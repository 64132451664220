.withdraw-farm-container {
    display: flex;
    flex-direction: column;
}

.withdraw-farm-container > h3 {
    transition-duration: .5s;
    transition-property: color;
    margin-top: 0;
    margin-bottom: 15px;
}

.withdraw-farm-container > h3 > span {
    color: #ed628b !important
}

.withdraw-farm-container > input {
    border: 1px solid #262626;
    border-radius: 15px;
    transition-duration: .5s;
    transition-property: background-color, color, border-color;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 600;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    outline: none;
}

.withdraw-farm-container > input:focus {
    border-color: #ed628b;
}

.withdraw-farm-manipulating {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.withdraw-farm-manipulating > .max {
    float: left;
    background-color: transparent;
    border: none;
    font-size: 16px;
    text-decoration: underline;
    transition-duration: .5s;
    transition-property: color;
    cursor: pointer;
}

.withdraw-farm-manipulating > .max:hover {
    text-decoration: none;
}

.withdraw-farm-manipulating > .farm-btn {
    float: right;
    border: none;
    border-radius: 10em;
    background-color: #ed628b;
    color: #fff;
    padding: 7px 15px;
    transition: .5s;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.withdraw-farm-manipulating > .farm-btn:hover {
    background-color: #dd4c76;
}