.pools-frame {
  margin-top: 80px;
  width: 1000px;
}

.pools-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
}

.pools-header-text {
  flex: 1;
  transition-duration: .5s;
  transition-property: color;
}

.pools-header-text h1 {
  margin: 0;
  font-size: 18px;
  transition-duration: .5s;
  transition-property: color;
}

.pools-header-text p {
  margin: 0;
  font-size: 15px;
  transition-duration: .5s;
  transition-property: color;
}

.pools-header-manip button {
  background-color: #ed628b;
  color: #fff;
  border: none;
  transition: .5s;
  cursor: pointer;
  border-radius: 20px;
  font-size: 16px;
  padding: 12px 30px;
}

.pools-header-manip button:hover {
  transition: .5s;
  background-color: #dd4c76;
}

.pools-adv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  height: 150px;
  margin-bottom: 25px;
  transition-duration: .5s;
  transition-property: background-color, color;
}

.pools-overview-box {
  border-radius: 15px;
  padding: 35px 30px;
  transition-duration: .5s;
  transition-property: background-color, color;
}

.pools-emty {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pools-emty > img {
  width: 50px;
  height: 40px;
}

.pools-emty > p {
  font-size: 18px;
  text-align: center;
  color: #b1b1b1;
}

@media screen and (max-width: 1013px) {
  .pools-frame {
    width: 97%;
  }
}