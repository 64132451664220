@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

html, body {
    padding: 0;
    margin: 0;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    height: 100%;
    transition-duration: .5s;
    transition-property: background-color;
}

#root {
    height: 100%;
}

.news-line {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ed628b;
    color: #fff;
    z-index: 100;
}

.header {
    display: flex;
    flex-direction: row;
    padding: 17px;
    transition-duration: .5s;
    transition-property: color, background-color;
    user-select: none;
}

.head-adv {
    border-radius: 20px;
    height: 70px;
    width: 290px;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    top: 95px;
    z-index: 200;
}

.menu-com {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.logo-container img {
    height: 50px;
}

.logo-text h1 {
    margin: 0;
    font-size: 32px;
    font-weight: 500;
    line-height: 23px;
}

.logo-text h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer {
    position: sticky;
    top: 100vh;
    margin-top: 50px;
    padding-bottom: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.f-links-center {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
}

.footer a {
    text-decoration: none;
    color: #585858;
    transition-duration: .5s;
    transition-property: color;
    margin: 0px 18px;
    padding: 5px 15px;
}

.footer a:hover {
    color: #303030;
}

.social-row {
    margin: 10px 0;
}

.social svg {
    padding-left: 15px;
}

.blocks {
    position: fixed;
    bottom: 30px;
    right: 15px;
}

@media screen and (max-width: 1624px) {
    .header {
        flex-direction: column;
    }

    .menu-com {
        margin-top: 15px;
        flex-direction: column-reverse;
    }
    
    .logo-container {
        justify-content: center;
    }

    .head-adv {
        top: 15px;
    }
}

@media screen and (max-width: 1276px) {
    .head-adv {
        top: 7px
    }
}

@media screen and (max-width: 1004px) {
    .footer a span {
        display: none;
    }
}

@media screen and (max-width: 900px) {
    .head-adv {
        display: none;
    }
}

@media screen and (max-width: 740px) {
    .footer {
        padding-bottom: 111px;
    }

    .footer a {
        margin: 0 5px;
    }
}

@media screen and (max-width: 613px) {
    .footer {
        padding-bottom: 170px;
    }
}

@media screen and (max-width: 521px) {
    .footer {
        padding-bottom: 231px;
    }
}

@media screen and (max-width: 511px) {
    body {
        zoom: 0.8;
    }
}