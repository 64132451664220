.LoaderBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loading img {
    animation: 2s loaderSpin infinite linear;
}

.LoaderBox span {
    margin-top: 10px;
    user-select: none;
}

@keyframes loaderSpin {
    0% {
        transform: rotate(0deg);
    } 100% {
        transform: rotate(360deg);
    }
}