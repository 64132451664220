.swap-container {
  width: 620px;
  border-radius: 40px;
  margin-top: 30px;
}

.mani-min .swap-manipulating {
  padding: 0px 0px;
  max-height: 0px;
}

.mani-max .swap-manipulating {
  padding: 26px 0px 0;
  max-height: 373px;
}

.swap-ads-area {
  height: 330px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.swap-box {
  padding: 0px 33px;
}

.swap-manipulating {
  overflow: hidden;
}

.swap-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
}

.swap-title {
  flex: 1;
  font-weight: 500;
  font-size: 20px;
}

.swap-heading button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

button.swap-footer-btn, .swap-footer button {
  width: 100%;
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  color: #59a2ff;
  background-color: #153d6f;
  margin: 26px 0px;
  padding: 25px 0px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  transition-duration: .5s;
  transition-property: color, background-color;
  user-select: none;
}

button.swap-footer-btn:hover, .swap-footer button:hover {
  background-color: #143764;
}

button.swap-footer-btn {
  display: none;
}

.swap-manipulating {
  transition: max-height .5s;
}

@media screen and (max-width: 1624px) {
  .mani-min .swap-ads-area {
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    transition-duration: .3s;
    transition-property: border-bottom-left-radius, border-bottom-right-radius;
  }
  
  .mani-max .swap-ads-area {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    transition-duration: .3s;
    transition-property: border-bottom-left-radius, border-bottom-right-radius;
  }

  .mani-max .swap-manipulating {
    padding: 26px 0px;
    max-height: 373px;
  }

  button.swap-footer-btn {
    display: block;
  }

  .swap-footer button {
    display: none;
  }
}

@media screen and (max-width: 635px) {
  .swap-container {
    width: auto;
    margin: 20px 10px;
  }

  .mani-max .swap-manipulating {
    max-height: 396px;
  }
}

@media screen and (max-width: 425px) {
  .footer a {
    margin: 0;
  }
}