@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
.menu {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.menu-container {
    border-radius: 10em;
    transition-duration: .5s;
    transition-property: color, background-color;
    display: flex;
    flex-direction: row;
}

.menu-container button {
    border-radius: 10em;
    font-size: 18px;
    line-height: 20px;
    border: none;
    background: transparent;
    padding: 10px 44px;
    margin: 5px;
    cursor: pointer;
    transition-duration: .5s;
    transition-property: color, background-color;
    cursor: pointer;
}

.menu-false {
    opacity: 0;
    transition-duration: .5s;
    transition-property: opacity;
    touch-action: none;
}

.menu-false button {
    cursor: default;
}

.menu-true {
    opacity: 1;
    transition-duration: .5s;
    transition-property: opacity;
    touch-action: auto;
}

.menuActive {
    cursor: default !important;
}

@media screen and (max-width: 1624px) {
    .menu-container {
        margin-top: 15px;
    }

    .menu-group {
        display: flex;
        flex-direction: row;
    }

    .menu-container button {
        flex: 1 1;
    }
}

@media screen and (max-width: 740px) {
    .menu {
        flex-direction: column;
    }

    .menu-container {
        display: flex;
        flex-direction: column;
        border-radius: 25px;
    }
}
.r-header-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    transition-duration: .5s;
    transition-property: background-color;
    z-index: 2;
}

.r-box {
    display: flex;
}

.net-wal {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-style: solid;
    border-width: 2px !important;
    transition: .5s;
    font-size: 18px;
    padding: 15px 20px;
    margin-right: 15px;
    border-radius: 15px;
    font-weight: 500;
    text-align: left;
}

.net-wal img {
    height: 25px;
}

.net-wal:hover {
    border-color: #ed628b !important;
}

.net-false {
    max-height: 0px;
    transition-duration: .5s;
    transition-property: max-height;
}

.net-true {
    max-height: 258px;
    transition-duration: .5s;
    transition-property: max-height;
}

.net-dropdown-container {
    position: absolute;
    overflow: hidden;
    z-index: 100;
    margin-top: 10px;
}

.net-dropdown {
    position: relative;
    padding: 15px;
    border-radius: 20px;
    transition-duration: .5s;
    transition-property: background-color;
}

.dropdown-btn {
    border-radius: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    transition-duration: .5s;
    transition-property: background-color;
}

.dropdown-text {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.net-dropdown > h3 {
    margin: 15px 0 15px 15px;
    font-weight: 400;
}

.dropdown-text > img {
    flex: 0 1;
    width: 30px;
}

.dropdown-text > p {
    flex: 1 1;
    font-size: 18px;
    font-weight: 400;
    margin: 0 15px;
}

.dropdown-text > section {
    flex: 0 1;
}

.dropdown-btn a {
    margin-top: 15px;
    color: #b0b0b0;
    text-decoration: none;
}

.dropdown-btn a:hover {
    text-decoration: underline;
}

.connect-wal {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-style: solid;
    border-color: transparent;
    border-width: 2px !important;
    font-size: 18px;
    padding: 15px 20px;
    border-radius: 15px;
    font-weight: 500;
    transition: .5s;
}

.connect-wal img {
    height: 25px;
    margin-left: 14px;
}

.connect-wal:hover {
    border-color: #ed628b !important;
}

.net-net {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.day-night {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
    transition-duration: .5s;
    transition-property: color;
}

input.apple-switch {
    position: relative;
    -webkit-appearance: none;
            appearance: none;
    outline: none;
    width: 50px;
    height: 30px;
    border-radius: 50px;
    transition-duration: 200ms;
    margin: 0 7px;
}

input.apple-switch:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 1px;
    width: 30px;
    height: 30px;
    background-color: #fff !important;
    border-radius: 50%;
    box-shadow: 2px 4px 6px rgba(0,0,0,0.2);
}

input.apple-switch:checked {
    border-color: #ed628b;
    box-shadow: inset 52px 0 0 0 #ed628b;
}

input.apple-switch:checked:after {
    left: 20px;
    box-shadow: -2px 4px 3px rgba(0,0,0,0.05);
}

@media screen and (max-width: 1624px) {
    .r-header-container {
        flex: 1 1;
        justify-content: center;
    }
}

@media screen and (max-width: 740px) {
    .r-header-container {
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 20px;
    }

    .r-box {
        display: flex;
        flex-direction: row;
        margin: 10px auto;
    }
}

@media screen and (max-width: 613px) {
    .r-header-container {
        left: 50%;
        -webkit-transform: translate(-50%);
                transform: translate(-50%);
    }

    .r-box {
        flex-direction: column-reverse;
        align-items: center;
        margin: none;
    }

    .net-net {
        flex-direction: row;
    }
    
    .net-container {
        margin: 5px;
    }

    .net-wal {
        margin: 5px;
    }
    
    .day-night {
        margin: 5px;
    }
}
html, body {
    padding: 0;
    margin: 0;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    height: 100%;
    transition-duration: .5s;
    transition-property: background-color;
}

#root {
    height: 100%;
}

.news-line {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ed628b;
    color: #fff;
    z-index: 100;
}

.header {
    display: flex;
    flex-direction: row;
    padding: 17px;
    transition-duration: .5s;
    transition-property: color, background-color;
    -webkit-user-select: none;
            user-select: none;
}

.head-adv {
    border-radius: 20px;
    height: 70px;
    width: 290px;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    top: 95px;
    z-index: 200;
}

.menu-com {
    flex: 1 1;
    display: flex;
    flex-direction: row;
}

.logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.logo-container img {
    height: 50px;
}

.logo-text h1 {
    margin: 0;
    font-size: 32px;
    font-weight: 500;
    line-height: 23px;
}

.logo-text h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer {
    position: -webkit-sticky;
    position: sticky;
    top: 100vh;
    margin-top: 50px;
    padding-bottom: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.f-links-center {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
}

.footer a {
    text-decoration: none;
    color: #585858;
    transition-duration: .5s;
    transition-property: color;
    margin: 0px 18px;
    padding: 5px 15px;
}

.footer a:hover {
    color: #303030;
}

.social-row {
    margin: 10px 0;
}

.social svg {
    padding-left: 15px;
}

.blocks {
    position: fixed;
    bottom: 30px;
    right: 15px;
}

@media screen and (max-width: 1624px) {
    .header {
        flex-direction: column;
    }

    .menu-com {
        margin-top: 15px;
        flex-direction: column-reverse;
    }
    
    .logo-container {
        justify-content: center;
    }

    .head-adv {
        top: 15px;
    }
}

@media screen and (max-width: 1276px) {
    .head-adv {
        top: 7px
    }
}

@media screen and (max-width: 1004px) {
    .footer a span {
        display: none;
    }
}

@media screen and (max-width: 900px) {
    .head-adv {
        display: none;
    }
}

@media screen and (max-width: 740px) {
    .footer {
        padding-bottom: 111px;
    }

    .footer a {
        margin: 0 5px;
    }
}

@media screen and (max-width: 613px) {
    .footer {
        padding-bottom: 170px;
    }
}

@media screen and (max-width: 521px) {
    .footer {
        padding-bottom: 231px;
    }
}

@media screen and (max-width: 511px) {
    body {
        zoom: 0.8;
    }
}
.LoaderBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loading img {
    -webkit-animation: 2s loaderSpin infinite linear;
            animation: 2s loaderSpin infinite linear;
}

.LoaderBox span {
    margin-top: 10px;
    -webkit-user-select: none;
            user-select: none;
}

@-webkit-keyframes loaderSpin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    } 100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes loaderSpin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    } 100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.choose-token-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-user-select: none;
            user-select: none;
}

.choose-token-box {
    position: absolute !important;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 410px;
    border-radius: 20px;
    z-index: 20000;
    overflow: hidden;
}

.choose-token-header {
    padding: 25px;
}

.token-header-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.choose-token-header h3 {
    margin: 0;
    color: #ed628b;
    flex: 1 1;
    font-size: 20px;
    font-weight: 600;
}

.choose-token-header .closeIcon {
    font-size: 20px;
    cursor: pointer;
}

.token-header-search-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 30px 0;
}

.token-header-search-box > input {
    flex: 1 1;
    border-radius: 20px;
    border: 1px solid;
    border-color: transparent;
    padding: 16px;
    font-size: 16px;
    transition: .5s;
    outline: none;
}

.token-header-search-box > input:focus {
    border-color:#ed628b;
    transition: .5s;
}

.recent-tokens {
    display: flex;
    flex-wrap: wrap;
}

.token-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 12px 12px 0;
    border-radius: 15px;
    cursor: pointer;
    transition: .5s;
}

.token-button:hover {
    background-color: #ed628b !important;
    color: #fff !important;
    transition: .5s;
}

.token-button img {
    height: 20px;
    margin: 0 8px 0 10px;
}

.token-button span {
    font-size: 14px;
    margin: 10px 10px 10px 0;
}

.choose-token-scroll {
    height: 370px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.token-scroll-container {
    height: 87%;
    overflow-x: hidden;
    overflow-y: auto;
}

.token-scroll-container::-webkit-scrollbar {
    width: 7px;
}
  
.token-scroll-container::-webkit-scrollbar-track {
    background: transparent;
}
  
.token-scroll-container::-webkit-scrollbar-thumb {
    background-color: #333333;
    border-radius: 20px;
    border: 0px solid transparent;
}

.token-scroll-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    transition: .5s;
}

.token-scroll-item:hover {
    background-color: #ed628b;
    color: #fff;
    transition: .5s;
}

.token-scroll-item:hover > button {
    background-color: #fff;
    color: #000;
    transition: .5s;
}

.token-scroll-item > img {
    height: 30px;
}

.token-scroll-item > section {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    min-width: 30px;
    margin-left: 12px;
    -webkit-user-select: none;
            user-select: none;
}

.token-scroll-item > section > p {
    margin: 0;
    font-weight: 600;
}

.token-scroll-item section span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.token-scroll-item > button {
    background-color: #ed628b;
    color: #fff;
    border-radius: 10em;
    border: none;
    font-weight: 200;
    padding: 7px 20px;
    cursor: pointer;
    transition: .5s;
}

.token-scroll-item > button:hover {
    transition: .5s;
    background-color: #eee;
}

.isloading-true {
    height: 140px;
    transition-duration: .5s;
    transition-property: height;
}

.isloading-false {
    height: 605px;
    transition-duration: .5s;
    transition-property: height;
}

.search-notfound > p {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
}
.input-box {
    margin: 8px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.input-box > input {
    width: 100%;
    flex: 1 1;
    font-size: 24px;
    line-height: 20px;
    padding: 16px 18px;
    border: 1px solid transparent;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-right: none !important;
    outline: none;
    overflow: hidden;
    transition-duration: .5s;
    transition-property: border, background-color;
}

/* Chrome, Safari, Edge, Opera */
.input-box > input::-webkit-outer-spin-button,
.input-box > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-box > input[type=number] {
  -moz-appearance: textfield;
}

.input-box > input:focus {
    border-right: none !important;
    border: 1px solid #ed628b;
}

.btnSelectToken {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    border: 1px solid transparent;
    border-left: none;
    transition-duration: .5s;
    transition-property: border-top-color, border-bottom-color, background-color;
    -webkit-user-select: none;
            user-select: none;
}

.input-box input:focus + .btnSelectToken {
    border-top-color: #ed628b !important;
    border-bottom-color: #ed628b !important;
    border-left: none !important;
}

.btnSelectToken > button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 25px 32.5px 26px 32.5px;
    background-color: #ed628b;
    font-size: 18px;
    border: none;
    border-radius: 20px;
    line-height: 20px;
    color: #fff;
    cursor: pointer;
}

.btnSelectToken > button > img {
    height: 25px;
    margin-right: 15px;
}

@media screen and (max-width: 425px) {
    .input-box {
        flex-direction: column-reverse;
    }

    .btnSelectToken {
        border: none;
        background-color: transparent !important;
    }

    .btnSelectToken > button {
        width: 100%;
        justify-content: center;
        margin-bottom: 10px;
    }

    .input-box > input {
        width: 90%;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-right: 1px solid transparent !important;
    }

    .input-box > input:focus {
        border-right: 1px solid #ed628b !important;
        border: 1px solid #ed628b;
    }
}
.swap-container {
  width: 620px;
  border-radius: 40px;
  margin-top: 30px;
}

.mani-min .swap-manipulating {
  padding: 0px 0px;
  max-height: 0px;
}

.mani-max .swap-manipulating {
  padding: 26px 0px 0;
  max-height: 373px;
}

.swap-ads-area {
  height: 330px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.swap-box {
  padding: 0px 33px;
}

.swap-manipulating {
  overflow: hidden;
}

.swap-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  -webkit-user-select: none;
          user-select: none;
}

.swap-title {
  flex: 1 1;
  font-weight: 500;
  font-size: 20px;
}

.swap-heading button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

button.swap-footer-btn, .swap-footer button {
  width: 100%;
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  color: #59a2ff;
  background-color: #153d6f;
  margin: 26px 0px;
  padding: 25px 0px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  transition-duration: .5s;
  transition-property: color, background-color;
  -webkit-user-select: none;
          user-select: none;
}

button.swap-footer-btn:hover, .swap-footer button:hover {
  background-color: #143764;
}

button.swap-footer-btn {
  display: none;
}

.swap-manipulating {
  transition: max-height .5s;
}

@media screen and (max-width: 1624px) {
  .mani-min .swap-ads-area {
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    transition-duration: .3s;
    transition-property: border-bottom-left-radius, border-bottom-right-radius;
  }
  
  .mani-max .swap-ads-area {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    transition-duration: .3s;
    transition-property: border-bottom-left-radius, border-bottom-right-radius;
  }

  .mani-max .swap-manipulating {
    padding: 26px 0px;
    max-height: 373px;
  }

  button.swap-footer-btn {
    display: block;
  }

  .swap-footer button {
    display: none;
  }
}

@media screen and (max-width: 635px) {
  .swap-container {
    width: auto;
    margin: 20px 10px;
  }

  .mani-max .swap-manipulating {
    max-height: 396px;
  }
}

@media screen and (max-width: 425px) {
  .footer a {
    margin: 0;
  }
}
.pool-list-container {
    margin: 15px 0;
}

.pool-info {
    background-color: #ed628b;
    border-radius: 20px;
    padding: 22px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.pool-info-box {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.pool-info-logos {
    display: flex;
    flex-direction: row;
}

.pool-info-logos > img {
    width: 40px;
    border-radius: 10em;
}

.info-logos-b {
    border: 2px solid #ed628b;
    background-color: #ed628b;
    margin-left: -15px;
}

.pool-info-heading {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
}

.pool-info-heading > h3 {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    margin: 0;
    font-size: 28px;
    font-weight: 600;
    color: #fff;
}

.pool-info-heading > p {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    margin: 0;
    color: #fff;
    font-size: 18px;
    font-weight: 200px;
}

.pool-info-manipulating button {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 300;
    padding: 13px 25px;
    border: none;
    border-radius: 10em;
    cursor: pointer;
    transition-duration: .5s;
    background-color: #fff;
    transition-property: background-color;
}

.pool-info-manipulating button:hover {
    transition-duration: .5s;
    transition-property: background-color;
    background-color: #eee;
}
.pool-info-manipulating {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.pool-info-m-cont {
    display: flex;
    flex-direction: row;
}

.pool-info-m-cont > .farm {
    margin-right: 15px;
}

.pool-info-manipulating > button {
    margin-left: 15px;
}

.pool-info-manipulating > .pool-info-m-cont > .farm {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.pool-info-manipulating > .pool-info-m-cont > .farm > img {
    height: 30px;
    margin-right: 10px;
    margin-top:  -3px;
}
@media screen and (max-width: 843px) {
    .pool-info-box {
        flex-direction: column;
        align-items: flex-start;
    }

    .pool-info-heading {
        margin-left: 0px;
        margin-top: 10px;
    }
}

@media screen and (max-width: 773px) {
    .pool-info-manipulating {
        flex-direction: row-reverse;
    }

    .pool-info-manipulating > button {
        margin-left: 0;
        margin-right: 15px;
    }

    .pool-info-m-cont {
        flex-direction: column;
    }

    .pool-info-m-cont > .farm {
        margin-bottom: 10px;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 670px) {
    .pools-header-manip {
        display: flex;
        flex-direction: column;
    }

    .pools-header-manip > button {
        margin-right: 0 !important;
    }

    .pools-header-manip > button:first-child {
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 640px) {
    .pool-info-manipulating {
        flex-direction: column-reverse;
    }

    .pool-info-manipulating > button {
        margin-bottom: 15px;
        margin-right: 0;
        width: 100%;
    }

    .pool-info-m-cont {
        flex-direction: column-reverse;
    }

    .pool-info-m-cont > .farm {
        margin-bottom: 0;
        margin-top: 15px;
    }
}

@media screen and (max-width: 425px) {
    .pool-info {
        flex-direction: column;
    }

    .pool-info-box {
        align-items: center;
    }

    .pool-info-manipulating {
        margin-top: 15px;
    }

    .pool-info-heading > p {
        text-align: center;
    }
}
.pools-frame {
  margin-top: 80px;
  width: 1000px;
}

.pools-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
}

.pools-header-text {
  flex: 1 1;
  transition-duration: .5s;
  transition-property: color;
}

.pools-header-text h1 {
  margin: 0;
  font-size: 18px;
  transition-duration: .5s;
  transition-property: color;
}

.pools-header-text p {
  margin: 0;
  font-size: 15px;
  transition-duration: .5s;
  transition-property: color;
}

.pools-header-manip button {
  background-color: #ed628b;
  color: #fff;
  border: none;
  transition: .5s;
  cursor: pointer;
  border-radius: 20px;
  font-size: 16px;
  padding: 12px 30px;
}

.pools-header-manip button:hover {
  transition: .5s;
  background-color: #dd4c76;
}

.pools-adv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  height: 150px;
  margin-bottom: 25px;
  transition-duration: .5s;
  transition-property: background-color, color;
}

.pools-overview-box {
  border-radius: 15px;
  padding: 35px 30px;
  transition-duration: .5s;
  transition-property: background-color, color;
}

.pools-emty {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pools-emty > img {
  width: 50px;
  height: 40px;
}

.pools-emty > p {
  font-size: 18px;
  text-align: center;
  color: #b1b1b1;
}

@media screen and (max-width: 1013px) {
  .pools-frame {
    width: 97%;
  }
}
.input-box-poolfee {
    margin: 8px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.input-box-poolfee > input {
    width: 100%;
    flex: 1 1;
    font-size: 20px;
    line-height: 20px;
    padding: 13px 18px;
    border: 1px solid transparent;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-right: none !important;
    outline: none;
    overflow: hidden;
    transition-duration: .5s;
    transition-property: border, background-color;
}

.input-box-poolfee > input:focus {
    border-right: none !important;
    border: 1px solid #ed628b;
}

/* Chrome, Safari, Edge, Opera */
.input-box-poolfee > input::-webkit-outer-spin-button,
.input-box-poolfee > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-box-poolfee > input[type=number] {
  -moz-appearance: textfield;
}

.btnSelectFeeToken {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    border: 1px solid transparent;
    border-left: none;
    transition-duration: .5s;
    transition-property: border-top-color, border-bottom-color, background-color;
    -webkit-user-select: none;
            user-select: none;
}

.input-box-poolfee input:focus + .btnSelectFeeToken {
    border-top-color: #ed628b !important;
    border-bottom-color: #ed628b !important;
    border-left: none !important;
}

.feeTokenLogo {
    height: 20px;
    border-radius: 10em;
    margin-right: 10px;
}

.btnSelectFeeToken > button {
    display: flex;
    flex-direction: row;
    padding: 15px 23px;
    background-color: #ed628b;
    font-size: 18px;
    border: none;
    border-radius: 20px;
    line-height: 20px;
    color: #fff;
    cursor: pointer;
}

.pool-fee-balance {
    font-size: 11px;
}

.pool-fee-balance > span {
    color: #9d9d9d !important;
    -webkit-user-select: none;
            user-select: none;
}

.max-btn-container > button {
    border: none;
    background-color: transparent;
    font-size: 16px;
    cursor: pointer;
    transition-duration: .5s;
    transition-property: color;
}

@media screen and (max-width: 425px) {
    .input-box-poolfee {
        flex-direction: column-reverse;
    }

    .btnSelectFeeToken {
        border: none;
        background-color: transparent !important;
    }

    .btnSelectFeeToken > button {
        width: 100%;
        justify-content: center;
        margin-bottom: 10px;
    }

    .input-box-poolfee > input {
        width: 90%;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-right: 1px solid transparent !important;
    }

    .input-box-poolfee > input:focus {
        border-right: 1px solid #ed628b !important;
        border: 1px solid #ed628b;
    }
}
.pool-token-select-container {
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

.select-token-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #ed628b;
    border-radius: 15px;
    padding: 10px;
    transition-duration: .5s;
    transition-property: background-color, color;
    cursor: pointer;
}

.select-token-btn > section {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.select-token-btn > section > img {
    height: 20px;
    margin-right: 10px;
}

.select-token-btn > section > span {
    font-size: 16px;
    font-weight: 600;
    margin-top: 1px;
}

.pts-balance-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    font-size: 11px;
}

.pts-balance {
    display: flex;
    flex-direction: row-reverse;
}

span.in-usd {
    flex: 1 1;
    text-align: right;
    transition-duration: .5s;
    transition-property: color;
}

span.balance-text {
    color: #9d9d9d;
}

.balance-in-dollar {
    text-align: right;
    color: #9d9d9d;
}
.pool-add {
  border-radius: 20px;
  margin-top: 80px;
  width: 450px;
  transition-duration: .5s;
  transition-property: background-color;
}

.pool-add-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 25px;
  border-bottom: 1px solid #585858;
  transition-duration: .5s;
  transition-property: color;
}

.pool-add-heading-icon {
  font-size: 24px;
  margin-right: 20px;
  cursor: pointer;
}


.pool-add-heading h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.pool-add-body-box {
  padding: 0 25px;
  padding-top: 77px;
  padding-bottom: 77px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  transition-duration: .5s;
  transition-property: padding-top, padding-bottom;
}

.pool-add-body-box > h3 {
  color: #ed628b;
}

.validation-pair-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.validation-pair-container > span {
  font-size: 32px;
  font-weight: 600;
  margin: 0px 20px;
  transition-duration: .5s;
  transition-property: color;
  margin-bottom: 38px;
}

.pool-add-body-box > input {
  border: 1px solid transparent;
  border-radius: 15px;
  padding: 10px 15px;
  font-size: 16px;
  transition: .5s;
  outline: none;
}

.pool-add-body-box > input:focus {
  border: 1px solid #ed628b;
  transition: .5s;
}

/* Chrome, Safari, Edge, Opera */
.pool-add-body-box > input::-webkit-outer-spin-button,
.pool-add-body-box > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.pool-add-body-box > input[type=number] {
  -moz-appearance: textfield;
}

.pool-add-footer {
  display: flex;
  flex-direction: row;
}

.pool-add-footer > button {
  flex: 1 1;
  color: #fff;
  background-color: #ed628b;
  margin: 18px 25px;
  border: none;
  font-size: 20px;
  font-weight: 600;
  padding: 18px;
  border-radius: 20px;
  cursor: pointer;
  transition: .5s;
}

.pool-add-footer > button:hover {
  background-color: #dd4c76;
  transition: .5s;
}

.pool-add-body {
  overflow: hidden;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}

.pool-add-footer {
  overflow: hidden;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}

.pool-add-state-0 {
  height: 254px;
  transition-duration: .5s;
  transition-property: height;
}

.pool-add-state-1 {
  height: 254px;
  transition-duration: .5s;
  transition-property: height;
}

.pool-add-state-2 > .pool-add-body-box {
  padding-top: 50px;
  padding-bottom: 30px;
  transition-duration: .5s;
  transition-property: padding-top, padding-bottom;
}

.pool-add-state-2 {
  height: 385px;
  transition-duration: .5s;
  transition-property: height;
}

.pool-add-state-3, .pool-add-state-4 {
  height: 210px;
  transition-duration: .5s;
  transition-property: height;
}

.pool-add-footer-0, .pool-add-footer-1, .pool-add-footer-2 {
  height: 95px;
  transition-duration: .5s;
  transition-property: height;
}

.pool-add-footer-3, .pool-add-footer-3 {
  height: 0px;
  transition-duration: .5s;
  transition-property: height;
}

.pool-add-plus {
  text-align: center;
  font-size: 32px;
  font-weight: 300;
  padding: 25px 0;
}

.pool-add-only-token {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.pool-add-only-token > span {
  font-size: 13px;
  margin-left: 7px;
  transition-duration: .5s;
  transition-property: color;
}

.skip-pool-liq-btn:hover {
  transition-duration: .5s;
  transition-property: -webkit-filter;
  transition-property: filter;
  transition-property: filter, -webkit-filter;
  -webkit-filter: contrast(1.25);
          filter: contrast(1.25);
}

@media screen and (max-width: 380px) {
  .pool-add {
    width: 92%;
  }
}
.pool-liquidity {
  border-radius: 20px;
  margin-top: 80px;
  width: 450px;
  transition-duration: .5s;
  transition-property: background-color;
}

.pool-liquidity-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 25px;
  border-bottom: 1px solid #585858;
  transition-duration: .5s;
  transition-property: color;
}

.pool-liquidity-heading-icon {
  font-size: 24px;
  margin-right: 20px;
  cursor: pointer;
}


.pool-liquidity-heading h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.pool-liquidity-body-box {
  padding: 0 25px;
  padding-top: 77px;
  padding-bottom: 77px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  transition-duration: .5s;
  transition-property: padding-top, padding-bottom;
}

.pool-liquidity-body-box > h3 {
  color: #ed628b;
}

.validation-pair-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.validation-pair-container > span {
  font-size: 32px;
  font-weight: 600;
  margin: 0px 20px;
  transition-duration: .5s;
  transition-property: color;
  margin-bottom: 38px;
}

.pool-liquidity-body-box > input {
  border: 1px solid transparent;
  border-radius: 15px;
  padding: 10px 15px;
  font-size: 16px;
  transition: .5s;
  outline: none;
}

.pool-liquidity-body-box > input:focus {
  border: 1px solid #ed628b;
  transition: .5s;
}

/* Chrome, Safari, Edge, Opera */
.pool-aliquiditydd-body-box > input::-webkit-outer-spin-button,
.pool-liquidity-body-box > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.pool-liquidity-body-box > input[type=number] {
  -moz-appearance: textfield;
}

.pool-liquidity-footer {
  display: flex;
  flex-direction: row;
}

.pool-liquidity-footer > button {
  flex: 1 1;
  color: #fff;
  background-color: #ed628b;
  margin: 18px 25px;
  border: none;
  font-size: 20px;
  font-weight: 600;
  padding: 18px;
  border-radius: 20px;
  cursor: pointer;
  transition: .5s;
}

.pool-liquidity-footer > button:hover {
  background-color: #dd4c76;
  transition: .5s;
}

.pool-liquidity-body {
  overflow: hidden;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}

.pool-liquidity-footer {
  overflow: hidden;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}

.pool-liquidity-state-0 {
  height: 254px;
  transition-duration: .5s;
  transition-property: height;
}

.pool-liquidity-state-1 > .pool-liquidity-body-box {
  padding-top: 50px;
  padding-bottom: 30px;
  transition-duration: .5s;
  transition-property: padding-top, padding-bottom;
}

.pool-liquidity-state-1 {
  height: 385px;
  transition-duration: .5s;
  transition-property: height;
}

.pool-liquidity-state-2, .pool-liquidity-state-3 {
  height: 210px;
  transition-duration: .5s;
  transition-property: height;
}

.pool-liquidity-footer-1 {
  height: 95px;
  transition-duration: .5s;
  transition-property: height;
}

.pool-liquidity-footer-0, .pool-liquidity-footer-2, .pool-liquidity-footer-3 {
  height: 0px;
  transition-duration: .5s;
  transition-property: height;
}

.pool-liquidity-plus {
  text-align: center;
  font-size: 32px;
  font-weight: 300;
  padding: 25px 0;
}

.pool-liquidity-only-token {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.pool-liquidity-only-token > span {
  font-size: 13px;
  margin-left: 7px;
  transition-duration: .5s;
  transition-property: color;
}

.skip-liquidity-liq-btn:hover {
  transition-duration: .5s;
  transition-property: -webkit-filter;
  transition-property: filter;
  transition-property: filter, -webkit-filter;
  -webkit-filter: contrast(1.25);
          filter: contrast(1.25);
}

@media screen and (max-width: 380px) {
  .pool-liquidity {
    width: 92%;
  }
}
.removepool-liquidity {
  border-radius: 20px;
  margin-top: 80px;
  width: 450px;
  transition-duration: .5s;
  transition-property: background-color;
}

.removepool-liquidity-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 25px;
  border-bottom: 1px solid #585858;
  transition-duration: .5s;
  transition-property: color;
}

.removepool-liquidity-heading-icon {
  font-size: 24px;
  margin-right: 20px;
  cursor: pointer;
}


.removepool-liquidity-heading h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.removepool-liquidity-body-box {
  padding: 0 25px;
  padding-top: 77px;
  padding-bottom: 77px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  transition-duration: .5s;
  transition-property: padding-top, padding-bottom;
}

.removepool-liquidity-body-box > h3 {
  color: #ed628b;
}

.validation-pair-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.validation-pair-container > span {
  font-size: 32px;
  font-weight: 600;
  margin: 0px 20px;
  transition-duration: .5s;
  transition-property: color;
  margin-bottom: 38px;
}

.removepool-liquidity-body-box > input {
  border: 1px solid transparent;
  border-radius: 15px;
  padding: 10px 15px;
  font-size: 16px;
  transition: .5s;
  outline: none;
}

.removepool-liquidity-body-box > input:focus {
  border: 1px solid #ed628b;
  transition: .5s;
}

.removepool-liquidity-footer {
  display: flex;
  flex-direction: row;
}

.removepool-liquidity-footer > button {
  flex: 1 1;
  color: #fff;
  background-color: #ed628b;
  margin: 18px 25px;
  border: none;
  font-size: 20px;
  font-weight: 600;
  padding: 18px;
  border-radius: 20px;
  cursor: pointer;
  transition: .5s;
}

.removepool-liquidity-footer > button:hover {
  background-color: #dd4c76;
  transition: .5s;
}

.removepool-liquidity-body {
  overflow: hidden;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}

.removepool-liquidity-footer {
  overflow: hidden;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}

.removepool-liquidity-state-0 {
  height: 254px;
  transition-duration: .5s;
  transition-property: height;
}

.removepool-liquidity-state-1 > .removepool-liquidity-body-box {
  padding-top: 50px;
  padding-bottom: 30px;
  transition-duration: .5s;
  transition-property: padding-top, padding-bottom;
}

.removepool-liquidity-state-1 {
  height: 235px;
  transition-duration: .5s;
  transition-property: height;
}

.removepool-liquidity-state-2, .removepool-liquidity-state-3 {
  height: 210px;
  transition-duration: .5s;
  transition-property: height;
}

.removepool-liquidity-footer-1 {
  height: 95px;
  transition-duration: .5s;
  transition-property: height;
}

.removepool-liquidity-footer-0, .removepool-liquidity-footer-2, .removepool-liquidity-footer-3 {
  height: 0px;
  transition-duration: .5s;
  transition-property: height;
}

.removepool-liquidity-plus {
  text-align: center;
  font-size: 32px;
  font-weight: 300;
  padding: 25px 0;
}

.removepool-liquidity-only-token {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.removepool-liquidity-only-token > span {
  font-size: 13px;
  margin-left: 7px;
  transition-duration: .5s;
  transition-property: color;
}

.skip-liquidity-liq-btn:hover {
  transition-duration: .5s;
  transition-property: -webkit-filter;
  transition-property: filter;
  transition-property: filter, -webkit-filter;
  -webkit-filter: contrast(1.25);
          filter: contrast(1.25);
}

@media screen and (max-width: 380px) {
  .pool-liquidity {
    width: 92%;
  }
}
.input-box-fee {
    margin: 8px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.input-box-fee > input {
    width: 100%;
    flex: 1 1;
    font-size: 20px;
    line-height: 20px;
    padding: 13px 18px;
    border: 1px solid transparent;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-right: none !important;
    outline: none;
    overflow: hidden;
    transition-duration: .5s;
    transition-property: border, background-color;
}

.input-box-fee > input:focus {
    border-right: none !important;
    border: 1px solid #ed628b;
}

.btnSelectFeeToken {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    border: 1px solid transparent;
    border-left: none;
    transition-duration: .5s;
    transition-property: border-top-color, border-bottom-color, background-color;
    -webkit-user-select: none;
            user-select: none;
}

.input-box-fee input:focus + .btnSelectFeeToken {
    border-top-color: #ed628b !important;
    border-bottom-color: #ed628b !important;
    border-left: none !important;
}

.feeTokenLogo {
    height: 20px;
    border-radius: 10em;
    margin-right: 10px;
}

.btnSelectFeeToken > button {
    display: flex;
    flex-direction: row;
    padding: 15px 23px;
    background-color: #ed628b;
    font-size: 18px;
    border: none;
    border-radius: 20px;
    line-height: 20px;
    color: #fff;
    cursor: pointer;
}

@media screen and (max-width: 425px) {
    .input-box-fee {
        flex-direction: column-reverse;
    }

    .btnSelectFeeToken {
        border: none;
        background-color: transparent !important;
    }

    .btnSelectFeeToken > button {
        width: 100%;
        justify-content: center;
        margin-bottom: 10px;
    }

    .input-box-fee > input {
        width: 90%;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-right: 1px solid transparent !important;
    }

    .input-box-fee > input:focus {
        border-right: 1px solid #ed628b !important;
        border: 1px solid #ed628b;
    }
}
.token-add {
  border-radius: 20px;
  margin-top: 80px;
  width: 450px;
  transition-duration: .5s;
  transition-property: background-color;
}

.token-add-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 25px;
  border-bottom: 1px solid #585858;
  transition-duration: .5s;
  transition-property: color;
}

.token-add-heading-icon {
  font-size: 24px;
  margin-right: 20px;
  cursor: pointer;
}


.token-add-heading h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.token-add-body-box {
  padding: 77px 25px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.token-add-body-box > h3 {
  color: #ed628b;
}

.token-add-body-box > input {
  border: 1px solid transparent;
  border-radius: 15px;
  padding: 10px 15px;
  font-size: 16px;
  transition: .5s;
  outline: none;
}

.token-add-body-box > input:focus {
  border: 1px solid #ed628b;
  transition: .5s;
}

.token-add-footer {
  display: flex;
  flex-direction: row;
}

.token-add-footer > button {
  flex: 1 1;
  color: #fff;
  background-color: #ed628b;
  margin: 18px 25px;
  border: none;
  font-size: 20px;
  font-weight: 600;
  padding: 18px;
  border-radius: 20px;
  cursor: pointer;
  transition: .5s;
}

.token-add-footer > button:hover {
  background-color: #dd4c76;
  transition: .5s;
}

.token-add-body {
  overflow: hidden;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}

.token-add-footer {
  overflow: hidden;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}

.add-state-0 {
  height: 254px;
  transition-duration: .5s;
  transition-property: height;
}

.add-state-1 {
  height: 283px;
  transition-duration: .5s;
  transition-property: height;
}

.add-state-2, .add-state-3 {
  height: 234px;
  transition-duration: .5s;
  transition-property: height;
}

.add-footer-0, .add-footer-1 {
  height: 95px;
  transition-duration: .5s;
  transition-property: height;
}

.add-footer-2, .add-footer-3 {
  height: 0px;
  transition-duration: .5s;
  transition-property: height;
}

@media screen and (max-width: 380px) {
  .token-add {
    width: 92%;
  }
}
.token-activate {
  border-radius: 20px;
  margin-top: 80px;
  width: 450px;
  transition-duration: .5s;
  transition-property: background-color;
}

.token-activate-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 25px;
  border-bottom: 1px solid #585858;
  transition-duration: .5s;
  transition-property: color;
}

.token-activate-heading-icon {
  font-size: 24px;
  margin-right: 20px;
  cursor: pointer;
}

.token-activate-body {
  max-height: 254;
  transition: .5s;
}

.token-activate-heading h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.token-fee-body-box {
  padding: 30px 50px 127px 50px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
          user-select: none;
}

.token-fee-body-box > h3 {
  font-size: 20px;
  margin: 5px 0;
}

.token-activate-footer {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.token-activate-footer > button {
  flex: 1 1;
  color: #fff;
  background-color: #ed628b;
  margin: 18px 25px;
  border: none;
  font-size: 20px;
  font-weight: 600;
  padding: 18px;
  border-radius: 20px;
  cursor: pointer;
  transition: .5s;
}

.token-activate-footer > button:hover {
  background-color: #dd4c76;
  transition: .5s;
}

.token-active-body {
  overflow: hidden;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}

.token-active-footer {
  overflow: hidden;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}

.active-state-0 {
  height: 234px;
  transition-duration: .5s;
  transition-property: height;
}

.active-state-1 {
  height: 211px;
  transition-duration: .5s;
  transition-property: height;
}

.active-state-2, .active-state-3 {
  height: 234px;
  transition-duration: .5s;
  transition-property: height;
}

.active-footer-1 {
  height: 95px;
  transition-duration: .5s;
  transition-property: height;
}

.active-footer-0, .active-footer-2, .active-footer-3 {
  height: 0px;
  transition-duration: .5s;
  transition-property: height;
}

@media screen and (max-width: 380px) {
  .token-activate {
    width: 92%;
  }
}
.farm-list-container {
    margin: 15px 0;
}

.farm-info {
    background-color: #ed628b;
    border-radius: 20px;
    padding: 22px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.farm-info-box {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.farm-info-logos {
    display: flex;
    flex-direction: row;
}

.farm-info-logos > img {
    width: 40px;
    border-radius: 10em;
}

.info-logos-b {
    border: 2px solid #ed628b;
    background-color: #ed628b;
    margin-left: -15px;
}

.farm-info-heading {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
}

.farm-info-heading > h3 {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    margin: 0;
    font-size: 28px;
    font-weight: 600;
    color: #fff;
}

.farm-info-heading > p {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    margin: 0;
    color: #fff;
    font-size: 18px;
    font-weight: 200px;
}

.farm-info-manipulating button {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 300;
    padding: 13px 25px;
    border: none;
    border-radius: 10em;
    cursor: pointer;
    transition-duration: .5s;
    background-color: #fff;
    transition-property: background-color;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.farm-info-manipulating button > img {
    margin-right: 10px;
}

.farm-info-manipulating button:hover {
    transition-duration: .5s;
    transition-property: background-color;
    background-color: #eee;
}
.farm-info-manipulating {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.farm-info-m-cont {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 843px) {
    .farm-info-box {
        flex-direction: column;
        align-items: flex-start;
    }

    .farm-info-heading {
        margin-left: 0px;
        margin-top: 10px;
    }
}

@media screen and (max-width: 773px) {
    .farm-info-manipulating {
        flex-direction: row-reverse;
    }

    .farm-info-manipulating > button {
        margin-left: 0;
        margin-right: 15px;
    }

    .farm-info-m-cont {
        flex-direction: column;
    }

    .farm-info-m-cont > .farm {
        margin-bottom: 10px;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 670px) {
    .farm-header-manip {
        display: flex;
        flex-direction: column;
    }

    .farm-header-manip > button {
        margin-right: 0 !important;
    }

    .farm-header-manip > button:first-child {
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 640px) {
    .farm-info-manipulating {
        flex-direction: column-reverse;
    }

    .farm-info-manipulating > button {
        margin-bottom: 15px;
        margin-right: 0;
        width: 100%;
    }

    .farm-info-m-cont {
        flex-direction: column-reverse;
    }

    .farm-info-m-cont > .farm {
        margin-bottom: 0;
        margin-top: 15px;
    }
}

@media screen and (max-width: 425px) {
    .farm-info {
        flex-direction: column;
    }

    .farm-info-box {
        align-items: center;
    }

    .farm-info-manipulating {
        margin-top: 15px;
    }

    .farm-info-heading > p {
        text-align: center;
    }
}
.farms-frame {
  margin-top: 80px;
  width: 1000px;
}

.farms-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
}

.farms-header-text {
  flex: 1 1;
  transition-duration: .5s;
  transition-property: color;
}

.farms-header-text h1 {
  margin: 0;
  font-size: 18px;
  transition-duration: .5s;
  transition-property: color;
}

.farms-header-text p {
  margin: 0;
  font-size: 15px;
  transition-duration: .5s;
  transition-property: color;
}

.farms-header-manip button {
  background-color: #ed628b;
  color: #fff;
  border: none;
  transition: .5s;
  cursor: pointer;
  border-radius: 20px;
  font-size: 16px;
  padding: 12px 30px;
}

.farms-header-manip button:hover {
  transition: .5s;
  background-color: #dd4c76;
}

.farms-adv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  height: 150px;
  margin-bottom: 25px;
  transition-duration: .5s;
  transition-property: background-color, color;
}

.farms-overview-box {
  border-radius: 15px;
  padding: 35px 30px;
  transition-duration: .5s;
  transition-property: background-color, color;
}

.farms-emty {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.farms-emty > img {
  width: 50px;
  height: 40px;
}

.farms-emty > p {
  font-size: 18px;
  text-align: center;
  color: #b1b1b1;
}

@media screen and (max-width: 1013px) {
  .farms-frame {
    width: 97%;
  }
}
.choose-token-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-user-select: none;
            user-select: none;
}

.choose-token-box {
    position: absolute !important;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 410px;
    border-radius: 20px;
    z-index: 20000;
    overflow: hidden;
}

.choose-token-header {
    padding: 25px;
}

.token-header-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.choose-token-header h3 {
    margin: 0;
    color: #ed628b;
    flex: 1 1;
    font-size: 20px;
    font-weight: 600;
}

.choose-token-header .closeIcon {
    font-size: 20px;
    cursor: pointer;
}

.token-header-search-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 30px 0;
}

.token-header-search-box > input {
    flex: 1 1;
    border-radius: 20px;
    border: 1px solid;
    border-color: transparent;
    padding: 16px;
    font-size: 16px;
    transition: .5s;
    outline: none;
}

.token-header-search-box > input:focus {
    border-color:#ed628b;
    transition: .5s;
}

.recent-tokens {
    display: flex;
    flex-wrap: wrap;
}

.token-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 12px 12px 0;
    border-radius: 15px;
    cursor: pointer;
    transition: .5s;
}

.token-button:hover {
    background-color: #ed628b !important;
    color: #fff !important;
    transition: .5s;
}

.token-button img {
    height: 20px;
    margin: 0 8px 0 10px;
}

.token-button span {
    font-size: 14px;
    margin: 10px 10px 10px 0;
}

.choose-token-scroll {
    height: 370px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.token-scroll-container {
    height: 87%;
    overflow-x: hidden;
    overflow-y: auto;
}

.token-scroll-container::-webkit-scrollbar {
    width: 7px;
}
  
.token-scroll-container::-webkit-scrollbar-track {
    background: transparent;
}
  
.token-scroll-container::-webkit-scrollbar-thumb {
    background-color: #333333;
    border-radius: 20px;
    border: 0px solid transparent;
}

.token-scroll-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    transition: .5s;
}

.token-scroll-item:hover {
    background-color: #ed628b;
    color: #fff;
    transition: .5s;
}

.token-scroll-item:hover > button {
    background-color: #fff;
    color: #000;
    transition: .5s;
}

.token-scroll-item > img {
    height: 30px;
}

.token-scroll-item > section {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    min-width: 30px;
    margin-left: 12px;
    -webkit-user-select: none;
            user-select: none;
}

.token-scroll-item > section > p {
    margin: 0;
    font-weight: 600;
}

.token-scroll-item section span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.token-scroll-item > button {
    background-color: #ed628b;
    color: #fff;
    border-radius: 10em;
    border: none;
    font-weight: 200;
    padding: 7px 20px;
    cursor: pointer;
    transition: .5s;
}

.token-scroll-item > button:hover {
    transition: .5s;
    background-color: #eee;
}

.isloading-true {
    height: 140px;
    transition-duration: .5s;
    transition-property: height;
}

.isloading-false {
    height: 605px;
    transition-duration: .5s;
    transition-property: height;
}

.search-notfound > p {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
}
.pool-token-select-container {
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

.select-token-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #ed628b;
    border-radius: 15px;
    padding: 10px;
    transition-duration: .5s;
    transition-property: background-color, color;
    cursor: pointer;
}

.select-token-btn > section {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.select-token-btn > section > img {
    height: 20px;
    margin-right: 10px;
}

.select-token-btn > section > span {
    font-size: 16px;
    font-weight: 600;
    margin-top: 1px;
}

.pts-balance-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    font-size: 11px;
}

.pts-balance {
    display: flex;
    flex-direction: row-reverse;
}

span.in-usd {
    flex: 1 1;
    text-align: right;
    transition-duration: .5s;
    transition-property: color;
}

span.balance-text {
    color: #9d9d9d;
}

.balance-in-dollar {
    text-align: right;
    color: #9d9d9d;
}
.pool-add {
  border-radius: 20px;
  margin-top: 80px;
  width: 450px;
  transition-duration: .5s;
  transition-property: background-color;
}

.pool-add-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 25px;
  border-bottom: 1px solid #585858;
  transition-duration: .5s;
  transition-property: color;
}

.pool-add-heading-icon {
  font-size: 24px;
  margin-right: 20px;
  cursor: pointer;
}


.pool-add-heading h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.pool-add-body-box {
  padding: 0 25px;
  padding-top: 77px;
  padding-bottom: 77px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  transition-duration: .5s;
  transition-property: padding-top, padding-bottom;
}

.pool-add-body-box > h3 {
  color: #ed628b;
}

.validation-pair-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.validation-pair-container > span {
  font-size: 32px;
  font-weight: 600;
  margin: 0px 20px;
  transition-duration: .5s;
  transition-property: color;
  margin-bottom: 38px;
}

.pool-add-body-box > input {
  border: 1px solid transparent;
  border-radius: 15px;
  padding: 10px 15px;
  font-size: 16px;
  transition: .5s;
  outline: none;
}

.pool-add-body-box > input:focus {
  border: 1px solid #ed628b;
  transition: .5s;
}

/* Chrome, Safari, Edge, Opera */
.pool-add-body-box > input::-webkit-outer-spin-button,
.pool-add-body-box > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.pool-add-body-box > input[type=number] {
  -moz-appearance: textfield;
}

.pool-add-footer {
  display: flex;
  flex-direction: row;
}

.pool-add-footer > button {
  flex: 1 1;
  color: #fff;
  background-color: #ed628b;
  margin: 18px 25px;
  border: none;
  font-size: 20px;
  font-weight: 600;
  padding: 18px;
  border-radius: 20px;
  cursor: pointer;
  transition: .5s;
}

.pool-add-footer > button:hover {
  background-color: #dd4c76;
  transition: .5s;
}

.pool-add-body {
  overflow: hidden;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}

.pool-add-footer {
  overflow: hidden;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}

.pool-add-state-0 {
  height: 254px;
  transition-duration: .5s;
  transition-property: height;
}

.pool-add-state-1 {
  height: 254px;
  transition-duration: .5s;
  transition-property: height;
}

.pool-add-state-2 > .pool-add-body-box {
  padding-top: 50px;
  padding-bottom: 30px;
  transition-duration: .5s;
  transition-property: padding-top, padding-bottom;
}

.pool-add-state-2 {
  height: 385px;
  transition-duration: .5s;
  transition-property: height;
}

.pool-add-state-3, .pool-add-state-4 {
  height: 210px;
  transition-duration: .5s;
  transition-property: height;
}

.pool-add-footer-0, .pool-add-footer-1, .pool-add-footer-2 {
  height: 95px;
  transition-duration: .5s;
  transition-property: height;
}

.pool-add-footer-3, .pool-add-footer-3 {
  height: 0px;
  transition-duration: .5s;
  transition-property: height;
}

.pool-add-plus {
  text-align: center;
  font-size: 32px;
  font-weight: 300;
  padding: 25px 0;
}

.pool-add-only-token {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.pool-add-only-token > span {
  font-size: 13px;
  margin-left: 7px;
  transition-duration: .5s;
  transition-property: color;
}

.skip-pool-liq-btn:hover {
  transition-duration: .5s;
  transition-property: -webkit-filter;
  transition-property: filter;
  transition-property: filter, -webkit-filter;
  -webkit-filter: contrast(1.25);
          filter: contrast(1.25);
}

@media screen and (max-width: 380px) {
  .pool-add {
    width: 92%;
  }
}
.withdraw-farm-container {
    display: flex;
    flex-direction: column;
}

.withdraw-farm-container > h3 {
    transition-duration: .5s;
    transition-property: color;
    margin-top: 0;
    margin-bottom: 15px;
}

.withdraw-farm-container > h3 > span {
    color: #ed628b !important
}

.withdraw-farm-container > input {
    border: 1px solid #262626;
    border-radius: 15px;
    transition-duration: .5s;
    transition-property: background-color, color, border-color;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 600;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    outline: none;
}

.withdraw-farm-container > input:focus {
    border-color: #ed628b;
}

.withdraw-farm-manipulating {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.withdraw-farm-manipulating > .max {
    float: left;
    background-color: transparent;
    border: none;
    font-size: 16px;
    text-decoration: underline;
    transition-duration: .5s;
    transition-property: color;
    cursor: pointer;
}

.withdraw-farm-manipulating > .max:hover {
    text-decoration: none;
}

.withdraw-farm-manipulating > .farm-btn {
    float: right;
    border: none;
    border-radius: 10em;
    background-color: #ed628b;
    color: #fff;
    padding: 7px 15px;
    transition: .5s;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.withdraw-farm-manipulating > .farm-btn:hover {
    background-color: #dd4c76;
}
.adding-farm-container {
    display: flex;
    flex-direction: column;
}

.adding-farm-container > h3 {
    transition-duration: .5s;
    transition-property: color;
    margin-top: 0;
    margin-bottom: 15px;
}

.adding-farm-container > h3 > span {
    color: #ed628b !important
}

.adding-farm-container > input {
    border: 1px solid transparent;
    border-radius: 15px;
    transition-duration: .5s;
    transition-property: background-color, color, border-color;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 600;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    outline: none;
}

.adding-farm-container > input:focus {
    border-color: #ed628b;
}

.adding-farm-manipulating {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.adding-farm-manipulating > .max {
    float: left;
    background-color: transparent;
    border: none;
    font-size: 16px;
    text-decoration: underline;
    transition-duration: .5s;
    transition-property: color;
    cursor: pointer;
}

.adding-farm-manipulating > .max:hover {
    text-decoration: none;
}

.adding-farm-manipulating > .farm-btn {
    float: right;
    border: none;
    border-radius: 10em;
    background-color: #ed628b;
    color: #fff;
    padding: 7px 36px;
    transition: .5s;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: .5s;
}

.adding-farm-manipulating > .farm-btn:hover {
    background-color: #dd4c76;
}
.farm-container {
  border-radius: 20px;
  margin-top: 80px;
  width: 450px;
  transition-duration: .5s;
  transition-property: background-color;
}

.farm-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 25px;
  border-bottom: 1px solid #585858;
  transition-duration: .5s;
  transition-property: color;
}

.farm-heading-icon {
  font-size: 24px;
  margin-right: 20px;
  cursor: pointer;
}


.farm-heading h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.farm-body-box {
  padding: 0 25px;
  padding-top: 77px;
  padding-bottom: 77px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  transition-duration: .5s;
  transition-property: padding-top, padding-bottom;
}

.farm-body-box > h3 {
  color: #ed628b;
}

.validation-pair-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.validation-pair-container > span {
  font-size: 32px;
  font-weight: 600;
  margin: 0px 20px;
  transition-duration: .5s;
  transition-property: color;
  margin-bottom: 38px;
}

.farm-body-box > input {
  border: 1px solid transparent;
  border-radius: 15px;
  padding: 10px 15px;
  font-size: 16px;
  transition: .5s;
  outline: none;
}

.farm-body-box > input:focus {
  border: 1px solid #ed628b;
  transition: .5s;
}

.farm-body {
  overflow: hidden;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}

.farm-footer {
  overflow: hidden;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}

.farm-state-0 {
  height: 254px;
  transition-duration: .5s;
  transition-property: height;
}

.farm-state-1 > .farm-body-box {
  padding-top: 50px;
  padding-bottom: 30px;
  transition-duration: .5s;
  transition-property: padding-top, padding-bottom;
}

.is-farming-true {
  height: 385px;
  transition-duration: .5s;
  transition-property: height;
}

.is-farming-false {
  height: 240px;
  transition-duration: .5s;
  transition-property: height;
}

.farm-state-2, .farm-state-3 {
  height: 210px;
  transition-duration: .5s;
  transition-property: height;
}

.farm-only-token {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.farm-only-token > span {
  font-size: 13px;
  margin-left: 7px;
  transition-duration: .5s;
  transition-property: color;
}

.skip-liquidity-liq-btn:hover {
  transition-duration: .5s;
  transition-property: -webkit-filter;
  transition-property: filter;
  transition-property: filter, -webkit-filter;
  -webkit-filter: contrast(1.25);
          filter: contrast(1.25);
}

@media screen and (max-width: 380px) {
  .farm {
    width: 92%;
  }
}
