.menu {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.menu-container {
    border-radius: 10em;
    transition-duration: .5s;
    transition-property: color, background-color;
    display: flex;
    flex-direction: row;
}

.menu-container button {
    border-radius: 10em;
    font-size: 18px;
    line-height: 20px;
    border: none;
    background: transparent;
    padding: 10px 44px;
    margin: 5px;
    cursor: pointer;
    transition-duration: .5s;
    transition-property: color, background-color;
    cursor: pointer;
}

.menu-false {
    opacity: 0;
    transition-duration: .5s;
    transition-property: opacity;
    touch-action: none;
}

.menu-false button {
    cursor: default;
}

.menu-true {
    opacity: 1;
    transition-duration: .5s;
    transition-property: opacity;
    touch-action: auto;
}

.menuActive {
    cursor: default !important;
}

@media screen and (max-width: 1624px) {
    .menu-container {
        margin-top: 15px;
    }

    .menu-group {
        display: flex;
        flex-direction: row;
    }

    .menu-container button {
        flex: 1;
    }
}

@media screen and (max-width: 740px) {
    .menu {
        flex-direction: column;
    }

    .menu-container {
        display: flex;
        flex-direction: column;
        border-radius: 25px;
    }
}