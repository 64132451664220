.choose-token-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    user-select: none;
}

.choose-token-box {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 410px;
    border-radius: 20px;
    z-index: 20000;
    overflow: hidden;
}

.choose-token-header {
    padding: 25px;
}

.token-header-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.choose-token-header h3 {
    margin: 0;
    color: #ed628b;
    flex: 1;
    font-size: 20px;
    font-weight: 600;
}

.choose-token-header .closeIcon {
    font-size: 20px;
    cursor: pointer;
}

.token-header-search-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 30px 0;
}

.token-header-search-box > input {
    flex: 1;
    border-radius: 20px;
    border: 1px solid;
    border-color: transparent;
    padding: 16px;
    font-size: 16px;
    transition: .5s;
    outline: none;
}

.token-header-search-box > input:focus {
    border-color:#ed628b;
    transition: .5s;
}

.recent-tokens {
    display: flex;
    flex-wrap: wrap;
}

.token-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 12px 12px 0;
    border-radius: 15px;
    cursor: pointer;
    transition: .5s;
}

.token-button:hover {
    background-color: #ed628b !important;
    color: #fff !important;
    transition: .5s;
}

.token-button img {
    height: 20px;
    margin: 0 8px 0 10px;
}

.token-button span {
    font-size: 14px;
    margin: 10px 10px 10px 0;
}

.choose-token-scroll {
    height: 370px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.token-scroll-container {
    height: 87%;
    overflow-x: hidden;
    overflow-y: auto;
}

.token-scroll-container::-webkit-scrollbar {
    width: 7px;
}
  
.token-scroll-container::-webkit-scrollbar-track {
    background: transparent;
}
  
.token-scroll-container::-webkit-scrollbar-thumb {
    background-color: #333333;
    border-radius: 20px;
    border: 0px solid transparent;
}

.token-scroll-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    transition: .5s;
}

.token-scroll-item:hover {
    background-color: #ed628b;
    color: #fff;
    transition: .5s;
}

.token-scroll-item:hover > button {
    background-color: #fff;
    color: #000;
    transition: .5s;
}

.token-scroll-item > img {
    height: 30px;
}

.token-scroll-item > section {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 30px;
    margin-left: 12px;
    user-select: none;
}

.token-scroll-item > section > p {
    margin: 0;
    font-weight: 600;
}

.token-scroll-item section span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.token-scroll-item > button {
    background-color: #ed628b;
    color: #fff;
    border-radius: 10em;
    border: none;
    font-weight: 200;
    padding: 7px 20px;
    cursor: pointer;
    transition: .5s;
}

.token-scroll-item > button:hover {
    transition: .5s;
    background-color: #eee;
}

.isloading-true {
    height: 140px;
    transition-duration: .5s;
    transition-property: height;
}

.isloading-false {
    height: 605px;
    transition-duration: .5s;
    transition-property: height;
}

.search-notfound > p {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
}