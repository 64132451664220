.input-box {
    margin: 8px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.input-box > input {
    width: 100%;
    flex: 1;
    font-size: 24px;
    line-height: 20px;
    padding: 16px 18px;
    border: 1px solid transparent;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-right: none !important;
    outline: none;
    overflow: hidden;
    transition-duration: .5s;
    transition-property: border, background-color;
}

/* Chrome, Safari, Edge, Opera */
.input-box > input::-webkit-outer-spin-button,
.input-box > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-box > input[type=number] {
  -moz-appearance: textfield;
}

.input-box > input:focus {
    border-right: none !important;
    border: 1px solid #ed628b;
}

.btnSelectToken {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    border: 1px solid transparent;
    border-left: none;
    transition-duration: .5s;
    transition-property: border-top-color, border-bottom-color, background-color;
    user-select: none;
}

.input-box input:focus + .btnSelectToken {
    border-top-color: #ed628b !important;
    border-bottom-color: #ed628b !important;
    border-left: none !important;
}

.btnSelectToken > button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 25px 32.5px 26px 32.5px;
    background-color: #ed628b;
    font-size: 18px;
    border: none;
    border-radius: 20px;
    line-height: 20px;
    color: #fff;
    cursor: pointer;
}

.btnSelectToken > button > img {
    height: 25px;
    margin-right: 15px;
}

@media screen and (max-width: 425px) {
    .input-box {
        flex-direction: column-reverse;
    }

    .btnSelectToken {
        border: none;
        background-color: transparent !important;
    }

    .btnSelectToken > button {
        width: 100%;
        justify-content: center;
        margin-bottom: 10px;
    }

    .input-box > input {
        width: 90%;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-right: 1px solid transparent !important;
    }

    .input-box > input:focus {
        border-right: 1px solid #ed628b !important;
        border: 1px solid #ed628b;
    }
}