.token-add {
  border-radius: 20px;
  margin-top: 80px;
  width: 450px;
  transition-duration: .5s;
  transition-property: background-color;
}

.token-add-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 25px;
  border-bottom: 1px solid #585858;
  transition-duration: .5s;
  transition-property: color;
}

.token-add-heading-icon {
  font-size: 24px;
  margin-right: 20px;
  cursor: pointer;
}


.token-add-heading h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.token-add-body-box {
  padding: 77px 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.token-add-body-box > h3 {
  color: #ed628b;
}

.token-add-body-box > input {
  border: 1px solid transparent;
  border-radius: 15px;
  padding: 10px 15px;
  font-size: 16px;
  transition: .5s;
  outline: none;
}

.token-add-body-box > input:focus {
  border: 1px solid #ed628b;
  transition: .5s;
}

.token-add-footer {
  display: flex;
  flex-direction: row;
}

.token-add-footer > button {
  flex: 1;
  color: #fff;
  background-color: #ed628b;
  margin: 18px 25px;
  border: none;
  font-size: 20px;
  font-weight: 600;
  padding: 18px;
  border-radius: 20px;
  cursor: pointer;
  transition: .5s;
}

.token-add-footer > button:hover {
  background-color: #dd4c76;
  transition: .5s;
}

.token-add-body {
  overflow: hidden;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.token-add-footer {
  overflow: hidden;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.add-state-0 {
  height: 254px;
  transition-duration: .5s;
  transition-property: height;
}

.add-state-1 {
  height: 283px;
  transition-duration: .5s;
  transition-property: height;
}

.add-state-2, .add-state-3 {
  height: 234px;
  transition-duration: .5s;
  transition-property: height;
}

.add-footer-0, .add-footer-1 {
  height: 95px;
  transition-duration: .5s;
  transition-property: height;
}

.add-footer-2, .add-footer-3 {
  height: 0px;
  transition-duration: .5s;
  transition-property: height;
}

@media screen and (max-width: 380px) {
  .token-add {
    width: 92%;
  }
}