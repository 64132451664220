.removepool-liquidity {
  border-radius: 20px;
  margin-top: 80px;
  width: 450px;
  transition-duration: .5s;
  transition-property: background-color;
}

.removepool-liquidity-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 25px;
  border-bottom: 1px solid #585858;
  transition-duration: .5s;
  transition-property: color;
}

.removepool-liquidity-heading-icon {
  font-size: 24px;
  margin-right: 20px;
  cursor: pointer;
}


.removepool-liquidity-heading h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.removepool-liquidity-body-box {
  padding: 0 25px;
  padding-top: 77px;
  padding-bottom: 77px;
  flex: 1;
  display: flex;
  flex-direction: column;
  transition-duration: .5s;
  transition-property: padding-top, padding-bottom;
}

.removepool-liquidity-body-box > h3 {
  color: #ed628b;
}

.validation-pair-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.validation-pair-container > span {
  font-size: 32px;
  font-weight: 600;
  margin: 0px 20px;
  transition-duration: .5s;
  transition-property: color;
  margin-bottom: 38px;
}

.removepool-liquidity-body-box > input {
  border: 1px solid transparent;
  border-radius: 15px;
  padding: 10px 15px;
  font-size: 16px;
  transition: .5s;
  outline: none;
}

.removepool-liquidity-body-box > input:focus {
  border: 1px solid #ed628b;
  transition: .5s;
}

.removepool-liquidity-footer {
  display: flex;
  flex-direction: row;
}

.removepool-liquidity-footer > button {
  flex: 1;
  color: #fff;
  background-color: #ed628b;
  margin: 18px 25px;
  border: none;
  font-size: 20px;
  font-weight: 600;
  padding: 18px;
  border-radius: 20px;
  cursor: pointer;
  transition: .5s;
}

.removepool-liquidity-footer > button:hover {
  background-color: #dd4c76;
  transition: .5s;
}

.removepool-liquidity-body {
  overflow: hidden;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.removepool-liquidity-footer {
  overflow: hidden;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.removepool-liquidity-state-0 {
  height: 254px;
  transition-duration: .5s;
  transition-property: height;
}

.removepool-liquidity-state-1 > .removepool-liquidity-body-box {
  padding-top: 50px;
  padding-bottom: 30px;
  transition-duration: .5s;
  transition-property: padding-top, padding-bottom;
}

.removepool-liquidity-state-1 {
  height: 235px;
  transition-duration: .5s;
  transition-property: height;
}

.removepool-liquidity-state-2, .removepool-liquidity-state-3 {
  height: 210px;
  transition-duration: .5s;
  transition-property: height;
}

.removepool-liquidity-footer-1 {
  height: 95px;
  transition-duration: .5s;
  transition-property: height;
}

.removepool-liquidity-footer-0, .removepool-liquidity-footer-2, .removepool-liquidity-footer-3 {
  height: 0px;
  transition-duration: .5s;
  transition-property: height;
}

.removepool-liquidity-plus {
  text-align: center;
  font-size: 32px;
  font-weight: 300;
  padding: 25px 0;
}

.removepool-liquidity-only-token {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.removepool-liquidity-only-token > span {
  font-size: 13px;
  margin-left: 7px;
  transition-duration: .5s;
  transition-property: color;
}

.skip-liquidity-liq-btn:hover {
  transition-duration: .5s;
  transition-property: filter;
  filter: contrast(1.25);
}

@media screen and (max-width: 380px) {
  .pool-liquidity {
    width: 92%;
  }
}