.r-header-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    transition-duration: .5s;
    transition-property: background-color;
    z-index: 2;
}

.r-box {
    display: flex;
}

.net-wal {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-style: solid;
    border-width: 2px !important;
    transition: .5s;
    font-size: 18px;
    padding: 15px 20px;
    margin-right: 15px;
    border-radius: 15px;
    font-weight: 500;
    text-align: left;
}

.net-wal img {
    height: 25px;
}

.net-wal:hover {
    border-color: #ed628b !important;
}

.net-false {
    max-height: 0px;
    transition-duration: .5s;
    transition-property: max-height;
}

.net-true {
    max-height: 258px;
    transition-duration: .5s;
    transition-property: max-height;
}

.net-dropdown-container {
    position: absolute;
    overflow: hidden;
    z-index: 100;
    margin-top: 10px;
}

.net-dropdown {
    position: relative;
    padding: 15px;
    border-radius: 20px;
    transition-duration: .5s;
    transition-property: background-color;
}

.dropdown-btn {
    border-radius: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    transition-duration: .5s;
    transition-property: background-color;
}

.dropdown-text {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.net-dropdown > h3 {
    margin: 15px 0 15px 15px;
    font-weight: 400;
}

.dropdown-text > img {
    flex: 0;
    width: 30px;
}

.dropdown-text > p {
    flex: 1;
    font-size: 18px;
    font-weight: 400;
    margin: 0 15px;
}

.dropdown-text > section {
    flex: 0;
}

.dropdown-btn a {
    margin-top: 15px;
    color: #b0b0b0;
    text-decoration: none;
}

.dropdown-btn a:hover {
    text-decoration: underline;
}

.connect-wal {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-style: solid;
    border-color: transparent;
    border-width: 2px !important;
    font-size: 18px;
    padding: 15px 20px;
    border-radius: 15px;
    font-weight: 500;
    transition: .5s;
}

.connect-wal img {
    height: 25px;
    margin-left: 14px;
}

.connect-wal:hover {
    border-color: #ed628b !important;
}

.net-net {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.day-night {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
    transition-duration: .5s;
    transition-property: color;
}

input.apple-switch {
    position: relative;
    appearance: none;
    outline: none;
    width: 50px;
    height: 30px;
    border-radius: 50px;
    transition-duration: 200ms;
    margin: 0 7px;
}

input.apple-switch:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 1px;
    width: 30px;
    height: 30px;
    background-color: #fff !important;
    border-radius: 50%;
    box-shadow: 2px 4px 6px rgba(0,0,0,0.2);
}

input.apple-switch:checked {
    border-color: #ed628b;
    box-shadow: inset 52px 0 0 0 #ed628b;
}

input.apple-switch:checked:after {
    left: 20px;
    box-shadow: -2px 4px 3px rgba(0,0,0,0.05);
}

@media screen and (max-width: 1624px) {
    .r-header-container {
        flex: 1;
        justify-content: center;
    }
}

@media screen and (max-width: 740px) {
    .r-header-container {
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 20px;
    }

    .r-box {
        display: flex;
        flex-direction: row;
        margin: 10px auto;
    }
}

@media screen and (max-width: 613px) {
    .r-header-container {
        left: 50%;
        transform: translate(-50%);
    }

    .r-box {
        flex-direction: column-reverse;
        align-items: center;
        margin: none;
    }

    .net-net {
        flex-direction: row;
    }
    
    .net-container {
        margin: 5px;
    }

    .net-wal {
        margin: 5px;
    }
    
    .day-night {
        margin: 5px;
    }
}