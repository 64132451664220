.farm-list-container {
    margin: 15px 0;
}

.farm-info {
    background-color: #ed628b;
    border-radius: 20px;
    padding: 22px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.farm-info-box {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.farm-info-logos {
    display: flex;
    flex-direction: row;
}

.farm-info-logos > img {
    width: 40px;
    border-radius: 10em;
}

.info-logos-b {
    border: 2px solid #ed628b;
    background-color: #ed628b;
    margin-left: -15px;
}

.farm-info-heading {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
}

.farm-info-heading > h3 {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    margin: 0;
    font-size: 28px;
    font-weight: 600;
    color: #fff;
}

.farm-info-heading > p {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    margin: 0;
    color: #fff;
    font-size: 18px;
    font-weight: 200px;
}

.farm-info-manipulating button {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 300;
    padding: 13px 25px;
    border: none;
    border-radius: 10em;
    cursor: pointer;
    transition-duration: .5s;
    background-color: #fff;
    transition-property: background-color;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.farm-info-manipulating button > img {
    margin-right: 10px;
}

.farm-info-manipulating button:hover {
    transition-duration: .5s;
    transition-property: background-color;
    background-color: #eee;
}
.farm-info-manipulating {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.farm-info-m-cont {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 843px) {
    .farm-info-box {
        flex-direction: column;
        align-items: flex-start;
    }

    .farm-info-heading {
        margin-left: 0px;
        margin-top: 10px;
    }
}

@media screen and (max-width: 773px) {
    .farm-info-manipulating {
        flex-direction: row-reverse;
    }

    .farm-info-manipulating > button {
        margin-left: 0;
        margin-right: 15px;
    }

    .farm-info-m-cont {
        flex-direction: column;
    }

    .farm-info-m-cont > .farm {
        margin-bottom: 10px;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 670px) {
    .farm-header-manip {
        display: flex;
        flex-direction: column;
    }

    .farm-header-manip > button {
        margin-right: 0 !important;
    }

    .farm-header-manip > button:first-child {
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 640px) {
    .farm-info-manipulating {
        flex-direction: column-reverse;
    }

    .farm-info-manipulating > button {
        margin-bottom: 15px;
        margin-right: 0;
        width: 100%;
    }

    .farm-info-m-cont {
        flex-direction: column-reverse;
    }

    .farm-info-m-cont > .farm {
        margin-bottom: 0;
        margin-top: 15px;
    }
}

@media screen and (max-width: 425px) {
    .farm-info {
        flex-direction: column;
    }

    .farm-info-box {
        align-items: center;
    }

    .farm-info-manipulating {
        margin-top: 15px;
    }

    .farm-info-heading > p {
        text-align: center;
    }
}