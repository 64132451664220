.input-box-poolfee {
    margin: 8px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.input-box-poolfee > input {
    width: 100%;
    flex: 1;
    font-size: 20px;
    line-height: 20px;
    padding: 13px 18px;
    border: 1px solid transparent;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-right: none !important;
    outline: none;
    overflow: hidden;
    transition-duration: .5s;
    transition-property: border, background-color;
}

.input-box-poolfee > input:focus {
    border-right: none !important;
    border: 1px solid #ed628b;
}

/* Chrome, Safari, Edge, Opera */
.input-box-poolfee > input::-webkit-outer-spin-button,
.input-box-poolfee > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-box-poolfee > input[type=number] {
  -moz-appearance: textfield;
}

.btnSelectFeeToken {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    border: 1px solid transparent;
    border-left: none;
    transition-duration: .5s;
    transition-property: border-top-color, border-bottom-color, background-color;
    user-select: none;
}

.input-box-poolfee input:focus + .btnSelectFeeToken {
    border-top-color: #ed628b !important;
    border-bottom-color: #ed628b !important;
    border-left: none !important;
}

.feeTokenLogo {
    height: 20px;
    border-radius: 10em;
    margin-right: 10px;
}

.btnSelectFeeToken > button {
    display: flex;
    flex-direction: row;
    padding: 15px 23px;
    background-color: #ed628b;
    font-size: 18px;
    border: none;
    border-radius: 20px;
    line-height: 20px;
    color: #fff;
    cursor: pointer;
}

.pool-fee-balance {
    font-size: 11px;
}

.pool-fee-balance > span {
    color: #9d9d9d !important;
    user-select: none;
}

.max-btn-container > button {
    border: none;
    background-color: transparent;
    font-size: 16px;
    cursor: pointer;
    transition-duration: .5s;
    transition-property: color;
}

@media screen and (max-width: 425px) {
    .input-box-poolfee {
        flex-direction: column-reverse;
    }

    .btnSelectFeeToken {
        border: none;
        background-color: transparent !important;
    }

    .btnSelectFeeToken > button {
        width: 100%;
        justify-content: center;
        margin-bottom: 10px;
    }

    .input-box-poolfee > input {
        width: 90%;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-right: 1px solid transparent !important;
    }

    .input-box-poolfee > input:focus {
        border-right: 1px solid #ed628b !important;
        border: 1px solid #ed628b;
    }
}