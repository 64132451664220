.farm-container {
  border-radius: 20px;
  margin-top: 80px;
  width: 450px;
  transition-duration: .5s;
  transition-property: background-color;
}

.farm-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 25px;
  border-bottom: 1px solid #585858;
  transition-duration: .5s;
  transition-property: color;
}

.farm-heading-icon {
  font-size: 24px;
  margin-right: 20px;
  cursor: pointer;
}


.farm-heading h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.farm-body-box {
  padding: 0 25px;
  padding-top: 77px;
  padding-bottom: 77px;
  flex: 1;
  display: flex;
  flex-direction: column;
  transition-duration: .5s;
  transition-property: padding-top, padding-bottom;
}

.farm-body-box > h3 {
  color: #ed628b;
}

.validation-pair-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.validation-pair-container > span {
  font-size: 32px;
  font-weight: 600;
  margin: 0px 20px;
  transition-duration: .5s;
  transition-property: color;
  margin-bottom: 38px;
}

.farm-body-box > input {
  border: 1px solid transparent;
  border-radius: 15px;
  padding: 10px 15px;
  font-size: 16px;
  transition: .5s;
  outline: none;
}

.farm-body-box > input:focus {
  border: 1px solid #ed628b;
  transition: .5s;
}

.farm-body {
  overflow: hidden;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.farm-footer {
  overflow: hidden;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.farm-state-0 {
  height: 254px;
  transition-duration: .5s;
  transition-property: height;
}

.farm-state-1 > .farm-body-box {
  padding-top: 50px;
  padding-bottom: 30px;
  transition-duration: .5s;
  transition-property: padding-top, padding-bottom;
}

.is-farming-true {
  height: 385px;
  transition-duration: .5s;
  transition-property: height;
}

.is-farming-false {
  height: 240px;
  transition-duration: .5s;
  transition-property: height;
}

.farm-state-2, .farm-state-3 {
  height: 210px;
  transition-duration: .5s;
  transition-property: height;
}

.farm-only-token {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.farm-only-token > span {
  font-size: 13px;
  margin-left: 7px;
  transition-duration: .5s;
  transition-property: color;
}

.skip-liquidity-liq-btn:hover {
  transition-duration: .5s;
  transition-property: filter;
  filter: contrast(1.25);
}

@media screen and (max-width: 380px) {
  .farm {
    width: 92%;
  }
}