.pool-token-select-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.select-token-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #ed628b;
    border-radius: 15px;
    padding: 10px;
    transition-duration: .5s;
    transition-property: background-color, color;
    cursor: pointer;
}

.select-token-btn > section {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.select-token-btn > section > img {
    height: 20px;
    margin-right: 10px;
}

.select-token-btn > section > span {
    font-size: 16px;
    font-weight: 600;
    margin-top: 1px;
}

.pts-balance-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    font-size: 11px;
}

.pts-balance {
    display: flex;
    flex-direction: row-reverse;
}

span.in-usd {
    flex: 1;
    text-align: right;
    transition-duration: .5s;
    transition-property: color;
}

span.balance-text {
    color: #9d9d9d;
}

.balance-in-dollar {
    text-align: right;
    color: #9d9d9d;
}