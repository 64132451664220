.token-activate {
  border-radius: 20px;
  margin-top: 80px;
  width: 450px;
  transition-duration: .5s;
  transition-property: background-color;
}

.token-activate-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 25px;
  border-bottom: 1px solid #585858;
  transition-duration: .5s;
  transition-property: color;
}

.token-activate-heading-icon {
  font-size: 24px;
  margin-right: 20px;
  cursor: pointer;
}

.token-activate-body {
  max-height: 254;
  transition: .5s;
}

.token-activate-heading h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.token-fee-body-box {
  padding: 30px 50px 127px 50px;
  flex: 1;
  display: flex;
  flex-direction: column;
  user-select: none;
}

.token-fee-body-box > h3 {
  font-size: 20px;
  margin: 5px 0;
}

.token-activate-footer {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.token-activate-footer > button {
  flex: 1;
  color: #fff;
  background-color: #ed628b;
  margin: 18px 25px;
  border: none;
  font-size: 20px;
  font-weight: 600;
  padding: 18px;
  border-radius: 20px;
  cursor: pointer;
  transition: .5s;
}

.token-activate-footer > button:hover {
  background-color: #dd4c76;
  transition: .5s;
}

.token-active-body {
  overflow: hidden;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.token-active-footer {
  overflow: hidden;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.active-state-0 {
  height: 234px;
  transition-duration: .5s;
  transition-property: height;
}

.active-state-1 {
  height: 211px;
  transition-duration: .5s;
  transition-property: height;
}

.active-state-2, .active-state-3 {
  height: 234px;
  transition-duration: .5s;
  transition-property: height;
}

.active-footer-1 {
  height: 95px;
  transition-duration: .5s;
  transition-property: height;
}

.active-footer-0, .active-footer-2, .active-footer-3 {
  height: 0px;
  transition-duration: .5s;
  transition-property: height;
}

@media screen and (max-width: 380px) {
  .token-activate {
    width: 92%;
  }
}